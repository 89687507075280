<template>
  <div class="p-2">
    <h1 class="text-2xl my-2">Les boissons</h1>
    <div v-for="drink in drinks" :key="drink.name" class="rounded-xl shadow bg-gray-700 my-4 p-1 grid grid-cols-4">
      <div class="content-center">
        <img class="h-14 w-auto mx-auto" :src="drink.image">
      </div>
      <div class="col-span-3 p-1">
        <div class="text-left text-lg font-semibold">
          {{ drink.name }}
        </div>
        <div class="text-justify">
          {{ drink.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Drink",
  data () {
    return {
      drinks: [
        {
          'name': 'Bière',
          'description': 'La bière est une boisson alcoolisée obtenue par fermentation alcoolique d\'un moût de produits végétaux amylacés tels que l\'orge, le maïs, le riz, la banane, le manioc...',
          'image': require('../assets/biere.png')
        },
        {
          'name': 'Blanche',
          'description': 'La Hoegaarden est une bière belge blanche qui compte parmi les plus connues hors du royaume de Belgique. C\'est la seule bière blanche à être proposée en fûts de 6L PerfectDraft ce qui la rend incontournable !\n' +
              '\n' +
              'Elle est brassée avec de la coriandre et des zestes de citron pour plus de recherche de goût et de fraîcheur. La Hoegaardenprésente une intrigante robe d\'un blond troublé qui s\'explique par le fait que cette bière n\'est pas filtrée avant l\'embouteillage et contient donc encore des levures en suspend',
          'image': require('../assets/blanche.png')
        },
        {
          'name': 'Lupulus',
          'description': 'La Lupulus est une bière belge. La triple titre à 8,5% et est caractérisée par une rafraîchissante amertume maitrisée et ajustée par une note épicée de thym.',
          'image': require('../assets/lupulus.png')
        },
        {
          'name': 'Paix-Dieux',
          'description': 'Une triple de 10% onctueuse et pleine de caractère; une bière artisanale dans la plus pure tradition des bières belges d’abbaye. Brassée uniquement les soirs de pleine lune.',
          'image': require('../assets/paix.png')
        },
        {
          'name': 'TK',
          'description': 'Sa texture soyeuse, rehaussée par des notes douces et épicées. Le goût d’une bière rafraîchissante et parfaitement équilibrée, inspirée d’une recette à trois grains historique.',
          'image': require('../assets/tk.png')
        },
        {
          'name': 'Bière Tabasco',
          'description': 'Le Tabasco, ou sauce Tabasco, est une sauce pimentée de marque déposée, liquide, à base de piments rouges mûrs à point fermentés en tonneaux, de vinaigre et de sel.',
          'image': require('../assets/tabasco.png')
        },
        {
          'name': 'Kiss Cool',
          'description': 'Le cocktail Kiss Cool est une boisson reconnue pour sa fraîcheur et l\'effet "haleine fraîche" qu\'il procure. Fait à base de vodka, il reste assez fort en alcool et est principalement consommé dans les bars dansant ou les bars de boite de nuit',
          'image': require('../assets/get.png')
        },
        {
          'name': 'Saumon',
          'description': 'Cette boisson de tarlouze est principalement consommée par des tennismen. Composée d\'une larme de grenadine et d\'une rivière de blanche, cette pratique est toutefois fortement déconseillée, tout simplement par respect pour le produit.',
          'image': require('../assets/saumon.png')
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
